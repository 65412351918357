import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        globalVariables: {}
    },
    mutations: {
        setGlobalVariable(state, { key, value }) {
            Vue.set(state.globalVariables, key, value)
        }
    },
    actions: {
        setGlobalVariable({ commit }, { key, value }) {
            commit("setGlobalVariable", { key, value })
        }
    },
    getters: {
        getGlobalVariable: (state) => (key) => {
            return state.globalVariables[key]
        }
    }
})

export default {
    setGlobalVariable(key, value) {
        store.dispatch("setGlobalVariable", { key, value })
    },
    getGlobalVariable(key) {
        return store.getters.getGlobalVariable(key)
    },
    store
} 