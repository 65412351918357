<template>
    <div>
        <k-wms-leaflet-map
            :center="center"
            :markers="markers"
            :polygons="this.polygons ? JSON.parse(this.polygons) : []"
            :fullscreen="fullscreen"
            :height="height"
            :zoom="zoom"
            :maxZoom="19"
            :gursSessionId="gursSessionId"
            :extraLayers="extraLayers"
            :legend="legend"
            ref="map"
        >
            <template v-slot:popup_content_marker v-if="popupContent">
                <p>{{popupContent}}</p>
            </template>

            <template v-slot:popup_content_polygon v-if="popupContent">
                <p>{{popupContent}}</p>
            </template>

            <template v-for="(index, name) in $slots" v-slot:[name]>
                <slot :name="name" />
            </template>
            <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
                <slot :name="name" v-bind="data"></slot>
            </template>
        </k-wms-leaflet-map>
    </div>
</template>

<script>
import KWMSLeafletMap from "@/components/k-leaflet-map/KWMSLeafletMap";
import commonprops from "./commonprops";

export default {
    components: {
        "k-wms-leaflet-map": KWMSLeafletMap,
    },
    props: {
        ...commonprops,
        extraLayers: {
            type: Array
        },
        legend: {
            type: Object
        },
    },
    computed: {
        center: function(){
            return {
                lat: this.lat,
                lng: this.lng
            }
        },
        markers: function(){
            return [{
                coordinates: this.center
            }]
        },
        leafletMapObject: function(){
            return this.$refs.map.leafletMapObject;
        }
    },
}
</script>

<style>
    @import "~leaflet/dist/leaflet.css";
    @import "~leaflet-fullscreen/dist/leaflet.fullscreen.css";
</style>