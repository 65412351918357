<template>
    <div>
        <k-simple-leaflet-map
            v-bind="$props"
            ref="map"
            @click="$emit('click', $event)"
            @polygon-mouseover="$emit('polygon-mouseover', $event)"
            @polygon-mouseout="$emit('polygon-mouseout', $event)"
        >
            <l-control-layers 
                v-if="getCountryPlugin('WMS_LEGEND_VISIBLE')"
                @ready="$emit('control-layers-ready', $event)"
                ref="controlLayers"
            />
            <l-wms-tile-layer
                v-for="(layer, index) in getLayers"
                :key="layer.name + index"
                :base-url="layer.url"
                :layers="layer.layers"
                :visible="layer.visible"
                :transparent="layer.transparent"
                :format="layer.format"
                :styles="layer.styles"
                :name="layer.name"
                :options="{ maxZoom: maxZoom }"
                :layer-type="layer.type"
            />

            <l-control
                v-if="gursSessionIdFailed && i18n && i18n.t"
                position="bottomright"
                class="text-danger p-5"
            >
                {{ i18n.t("leaflet_map:info:gurs_warning") }}
            </l-control>

            <!-- PASS ALL SLOTS TO CHILD COMPONENT -->
            <template v-for="(index, name) in $slots" v-slot:[name]>
                <slot :name="name"/>
            </template>
            <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
                <slot :name="name" v-bind="data"></slot>
            </template>

        </k-simple-leaflet-map>
    </div>
</template>

<script>
import ApiCalls from '@/components/utils/api.calls.js';
import KSimpleLeafletMap from './KSimpleLeafletMap';

import { LWMSTileLayer, LControlLayers, LControl } from 'vue2-leaflet';

import baseprops from './KSimpleLeafletMap-props'

import countrymixin from '@/mixins/countrymixin';

import i18n from '@/i18n.js';

export default {
    mixins: [ countrymixin ],
    beforeCreate() {
        this.i18n = i18n;
    },
    components: {
        KSimpleLeafletMap,
        LControl, LControlLayers,
        "l-wms-tile-layer": LWMSTileLayer
    },
    props: {
        ...baseprops,
        gursSessionId: String,
        extraLayers: {
            type: Array,
            default: function() {
                return []
            }
        },
        showDefaultBaseLayers: {
            type: Boolean,
            default: true
        },
        showDefaultOverlayLayers: {
            type: Boolean,
            default: true
        },
        defaultVisibleBaseLayerName: {
            type: String,
            default: null
        },
    },
    data: function() {
        return {
            requestedGursSessionId: "",
            gursSessionIdFailed: false
        }
    },
    methods: {
        getGursSessionId: function() {
            ApiCalls.getGursSessionId()
                .then(res => this.requestedGursSessionId=res)
                .catch(error => {
                    this.gursSessionIdFailed = true;
                    throw error;
                });
        },
        onCountryPluginLoad() {
            // Overriden from countrymixin
            if (this.getCountryPlugin("WMS_GET_GURS_SESSION")) {
                if (!this.gursSessionId) {
                    this.getGursSessionId()
                }
            }
        },
        addLayerToControl(layer, name) {
            if (layer.layerType === "base") {
                this.$refs.controlLayers.mapObject.addBaseLayer(layer, name);
            } else {
                this.$refs.controlLayers.mapObject.addOverlay(layer, name);
            }
        },
    },
    computed: {
        computedGursSessionId: function() {
            // NOTE: This is not currently used as all WMS layers are public
            // Kept in since it's probably going to change in the future.
            return this.gursSessionId ? this.gursSessionId : this.requestedGursSessionId;
        },
        _defaultBaseLayers: function() {
            if (!this.isCountryPluginLoaded || !this.showDefaultBaseLayers)
                return [];

            const baseLayers = this.getCountryPlugin("WMS_BASE_ORTOFOTO_LAYER");
            return baseLayers;
        },
        _defaultOverlayLayers: function() {
            if (!this.isCountryPluginLoaded || !this.showDefaultOverlayLayers)
                return [];

            let overlayLayers = this.getCountryPlugin("WMS_ORTOFOTO_LAYERS");
            if (overlayLayers.length > 0) {
                overlayLayers = overlayLayers.map(layer => ({
                    ...layer,
                    url: layer.url,
                }));
            }
            return overlayLayers;
        },
        _extraBaseLayers: function() {
            return this.extraLayers.filter(layer => layer.type === "base");
        },
        _extraOverlayLayers: function() {
            return this.extraLayers.filter(layer => layer.type !== "base");
        },
        getLayers: function() {
            let layers = [];

            // BaseLayers
            let baseLayers = [];
            baseLayers = baseLayers.concat(this._defaultBaseLayers);
            baseLayers = baseLayers.concat(this._extraBaseLayers);

            // select the default visible baselayer
            if (this.defaultVisibleBaseLayerName) {
                baseLayers = baseLayers.map(layer => {
                    let clayer = {...layer};
                    clayer.visible = clayer.name === this.defaultVisibleBaseLayerName;
                    return clayer;
                })
            }

            // OverlayLayers
            let overlayLayers = [];
            overlayLayers = overlayLayers.concat(this._defaultOverlayLayers);
            overlayLayers = overlayLayers.concat(this._extraOverlayLayers);
            
            layers = layers.concat(baseLayers, overlayLayers); 
            return layers;
        },
        leafletMapObject: function(){
            return this.$refs.map.leafletMapObject;
        },
    },
}
</script>