export default {
    lat: {
        type: Number,
        default: 46.15039
    },
    lng: {
        type: Number,
        default: 14.91421
    },
    height: {
        type: Number,
        default: 400
    },
    fullscreen: {
        type: Boolean,
        default: true
    },
    zoom: {
        type: Number,
        default: 17
    },
    popupContent: {
        type: String
    },
    gursSessionId: {
        type: String
    },
    polygons: {
        type: [Array, String]
    },
}