<template>
    <div>
        <l-map
            :max-zoom="maxZoom"
            :min-zoom="minZoom"
            :options="mapOptions"
            :zoom="zoom"
            :center="center"
            :style="getStyle"
            ref="map"
            @click="$emit('click', $event)"
        >
            <slot>
                <!-- Tile Layers -->
                <!-- Other tiles can be places here -->
                <l-tile-layer
                    :url="tileLayerUrl"
                    :options="{ maxZoom: maxZoom }"
                />
            </slot>

            <!-- Extra controls -->
            <slot name="extra_controls"></slot>

            <!-- Markers -->
            <slot name="markers">
                <l-marker
                    v-for="(marker, index) in markers"
                    :key="'m-'.concat(index)"
                    :lat-lng="marker.coordinates"
                >
                    <l-popup>
                        <slot name="popup_content_marker" v-bind:marker="marker">
                            {{marker}}
                        </slot>
                    </l-popup>
                </l-marker>
            </slot>

            <!-- Polygons -->
            <l-polygon
                v-for="(polygon, index) in polygons.filter(p => p.coordinates)"
                :key="'p-'.concat(index)"
                :lat-lngs="polygon.coordinates"
                :fill-color="polygon.style ? polygon.style.color : undefined"
                :stroke="polygon.style ? polygon.style.stroke : false"
                :color="polygon.style ? polygon.style.edgeColor : '#bac1dd'"
                :weight="polygon.style ? polygon.style.weight : undefined"
                :fill-opacity="polygon.style ? polygon.style.fillOpacity : undefined"
                @mouseover="changePolygonColor(polygon, $event, false, 'polygon-mouseover')"
                @mouseout="changePolygonColor(polygon, $event, true, 'polygon-mouseout')"
                @click="$emit('openmodal', 'p-'.concat(index))"
            >
                <template v-if="polygonPopupEnabled">
                    <div class="property_details" v-if="polygon.show_modal">
                        <l-tooltip>
                            {{ polygon.re_key }}
                        </l-tooltip>

                        <a :id="'p-'.concat(index)" :ref="'p-'.concat(index)" class="open-modal" :href="polygon.modal_url">
                            {{ polygon.modal_url }}
                        </a>
                    </div>
                    <div v-else>
                        <l-popup>
                            <slot name="popup_content_polygon" v-bind:polygon="polygon">
                                {{polygon}}
                            </slot>
                        </l-popup>
                    </div>
                </template>

            </l-polygon>

            <!-- Circles -->
            <l-circle
                v-for="(circle, index) in circles"
                :key="'c-'.concat(index)"
                :lat-lng="circle.coordinates"
                :radius="circle.radius"
                :fillColor="circle.color ? circle.color : '#85de9d'"
                :color="circle.color ? circle.color : '#85de9d'"
                :opacity="1"
                :stroke="true"
            >
                <l-popup>
                    <slot name="popup_content_circle" v-bind:circle="circle">
                        {{circle}}
                    </slot>
                </l-popup>
            </l-circle>

        </l-map>
    </div>
</template>

<script>

import L from 'leaflet';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen';
import { LMap, LTileLayer, LMarker, LPolygon, LCircle, LPopup, LTooltip, LControl } from 'vue2-leaflet';

// Fix for missing icons!
import { Icon } from 'leaflet';
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

import props from './KSimpleLeafletMap-props'

export default {
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LPolygon,
        LCircle,
        LPopup,
        LTooltip,
        LControl,
    },
    props: props,
    data: function() {
        return {
        }
    },
    computed: {
        getStyle: function(){
            return {
                "height": this.height + "px"
            }
        },
        leafletMapObject: function(){
            return this.$refs.map.mapObject;
        },
    },
    methods: {
        changePolygonColor: function (polygon, event, set_initial=false, outemit=false) {
            if(set_initial && polygon.initial_style) {
                event.target.setStyle(polygon.initial_style);
            } else {
                polygon.initial_style = {
                    color: event.target.options.color,
                    fillColor: event.target.options.fillColor,
                    fillOpacity: event.target.options.fillOpacity,
                }
                event.target.setStyle(this.mouseover_style);
            }
            this.$emit(outemit, polygon.property_id);
        },
        fitBounds() {
            if (this.getFitBoundsCoords.length || this.forceFitBoundsToMarkers) {
                this.leafletMapObject.fitBounds(
                    this.getFitBoundsCoords(),
                    {padding: [this.fitBoundsPadding, this.fitBoundsPadding]}
                )
            }
        },
        getFitBoundsCoords() {
            let markers = this.markers.map(m => [m.coordinates.lat, m.coordinates.lng]);
            let circles = this.circles.map(c => [c.coordinates.lat, c.coordinates.lng]);
            let polygons = this.polygons.map(c => c.coordinates.flat(1));
            return markers.concat(circles).concat(polygons);
        },
        invalidateMap() {
            this.$nextTick(() => {
                this.leafletMapObject.invalidateSize();
            })
        }
    },
    mounted() {
        const app = this;
        const map = app.leafletMapObject;
        this.$nextTick(() => {
            app.invalidateMap();
            L.DomEvent.addListener(document.body, 'invalidate-leaflet-maps', app.invalidateMap);
            if(this.legend){
                this.legend.addTo(map);
            }
            if(this.fullscreen){
                map.addControl(new L.Control.Fullscreen(
                    {
                        title: {
                            'false': "Celozaslonski način",
                            'true': "Izhod iz celozaslonskega načina"
                        },
                        position: 'topright'
                    }
                ));
            }
            this.fitBounds();
        });
    },  
    beforeDestroy() {
        L.DomEvent.removeListener(document.body, 'invalidate-leaflet-maps', this.invalidateMap);
    },
}
</script>

<style>
    @import "~leaflet/dist/leaflet.css";
    @import "~leaflet-fullscreen/dist/leaflet.fullscreen.css";
</style>