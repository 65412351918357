import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

// import {fireLogoutWarning} from "./swal.alerts.js";

//
// API Services
//
const ApiService = {
    init() {
        if (!Vue.axios)
            Vue.use(VueAxios, axios);
        // Vue.axios.interceptors.response.use(
        //     function (response) {
        //         // Any status code that lie within the range of 2xx cause this function to trigger
        //         // Do something with response data
        //         return response;
        //     }, 
        //     function (error) {
        //         // Any status codes that falls outside the range of 2xx cause this function to trigger
        //         // Do something with response error
        //         if(error.response && error.response.status==403) {
        //             // No permissions / Logged out
        //             fireLogoutWarning();
        //         } else {
        //             return Promise.reject(error);
        //         }
        //     }
        // );
    },
    
    get(resource, params) {
        return Vue.axios.get(`${resource}`, params);
    },
    post(resource, data, params) {
        return Vue.axios.post(`${resource}`, data, params);
    },
    update(resource, slug, params) {
        return Vue.axios.put(`${resource}/${slug}`, params);
    },
    put(resource, params) {
        return Vue.axios.put(`${resource}`, params);
    },
    patch(resource, params) {
        return Vue.axios.patch(`${resource}`, params);
    },
    delete(resource, data, params) {
        return Vue.axios.delete(`${resource}`, data, params);
    },
    options(resource, params){
        return Vue.axios.options(`${resource}`, params);
    },
    set_baseURL(baseURL) {
        Vue.axios.defaults.baseURL = baseURL;
        if (!Vue.axios.defaults.baseShareLinkUrl) {
            Vue.axios.defaults.baseShareLinkUrl = baseURL
        }
    },
    set_baseShareLinkUrl(baseURL) {
        Vue.axios.defaults.baseShareLinkUrl = baseURL;
    },
    _constructUrlHelper(baseURL, url, params) {
        if (baseURL.endsWith("/") && url && url.startsWith("/")) {
            url = url.substring(1);
        }
        let fullUrl = baseURL + url;
        if (params) {
            const queryString = new URLSearchParams(params).toString();
            fullUrl += `?${queryString}`;
        }
        return fullUrl;
    },
    constructUrl(url, params) {
        let baseURL = Vue.axios.defaults.baseURL ? Vue.axios.defaults.baseURL : "";
        let fullUrl = this._constructUrlHelper(baseURL, url, params);
        return fullUrl;
    },
    constructShareLinkUrl(url, params) {
        let baseURL = Vue.axios.defaults.baseShareLinkUrl ? Vue.axios.defaults.baseShareLinkUrl : "";
        let fullUrl = this._constructUrlHelper(baseURL, url, params);
        return fullUrl;
    },
    set_headers(headers) {
        for (const [header, value] of Object.entries(headers)) {
            Vue.axios.defaults.headers.common[header] = value;
        }
    }
}

export default ApiService;