<template>
    <div>
        <k-simple-leaflet-map
            :center="center"
            :markers="markers"
            :fullscreen="fullscreen"
            :height="height"
            :zoom="zoom"
            :maxZoom="19"
        >
            <template v-slot:popup_content_marker>
                <p>{{popupContent}}</p>
            </template>
        </k-simple-leaflet-map>
    </div>
</template>

<script>
import KSimpleLeafletMap from "@/components/k-leaflet-map/KSimpleLeafletMap";
import commonprops from "./commonprops";

export default {
    components: {
        KSimpleLeafletMap,
    },
    props: commonprops,
    computed: {
        center: function(){
            return {
                lat: this.lat,
                lng: this.lng
            }
        },
        markers: function(){
            return [{
                coordinates: this.center
            }]
        }
    },
}
</script>

<style>
    @import "~leaflet/dist/leaflet.css";
    @import "~leaflet-fullscreen/dist/leaflet.fullscreen.css";
</style>