<template>
    <div>
        <real-estate-leaflet-map-ortofoto
            v-bind="$props"
            :extraLayers="communalEquipmentLayers"
            ref="map"
        >
            <!-- PASS ALL SLOTS TO CHILD COMPONENT -->
            <template v-for="(index, name) in $slots" v-slot:[name]>
                <slot :name="name" />
            </template>
            <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
                <slot :name="name" v-bind="data"></slot>
            </template>
        </real-estate-leaflet-map-ortofoto>
    </div>
</template>

<script>
import L from 'leaflet';

import commonprops from "./commonprops"
import RealEstateLeafletMapOrtofoto from "./RealEstateLeafletMapOrtofoto"

export default {
    components: {
        RealEstateLeafletMapOrtofoto
    },
    props: commonprops,
    data: function() {
        const wmsUrlPrivate = 'https://storitve.eprostor.gov.si/ows-auth-wms/wms?sessionid='+ this.gursSessionId
        return {
            communalEquipmentLayers: [
                {
                    name: "Cesta",
                    layers: 'SI.GURS.KGI:CESTE_L,SI.GURS.KGI:CESTE_P,SI.GURS.KGI:CESTE_T',
                    transparent: true,
                    url: wmsUrlPrivate,
                    type: 'overlay',
                    visible: false,
                    format: 'image/png',
                    maxZoom: 19,
                },
                {
                    name: "Električna energija",
                    layers: 'SI.GURS.KGI:EL_ENERGIJA_L,SI.GURS.KGI:EL_ENERGIJA_P,SI.GURS.KGI:EL_ENERGIJA_T',
                    transparent: true,
                    url: wmsUrlPrivate,
                    type: 'overlay',
                    visible: true,
                    format: 'image/png',
                    maxZoom: 19,
                },
                {
                    name: "Komunikacije",
                    layers: 'SI.GURS.KGI:EL_KOMUNIKACIJE_L,SI.GURS.KGI:EL_KOMUNIKACIJE_P,SI.GURS.KGI:EL_KOMUNIKACIJE_T,SI.GURS.KGI:EL_KOMUNIKACIJE_CEV_L,SI.GURS.KGI:EL_KOMUNIKACIJE_KABEL_L',
                    transparent: true,
                    url: wmsUrlPrivate,
                    type: 'overlay',
                    visible: true,
                    format: 'image/png',
                    maxZoom: 19,
                },
                {
                    name: "Kanalizacija",
                    layers: 'SI.GURS.KGI:KANALIZACIJA_L,SI.GURS.KGI:KANALIZACIJA_P,SI.GURS.KGI:KANALIZACIJA_T',
                    transparent: true,
                    url: wmsUrlPrivate,
                    type: 'overlay',
                    visible: true,
                    format: 'image/png',
                    maxZoom: 19,
                },
                {
                    name: "Letališča",
                    layers: 'SI.GURS.KGI:LETALISCA_L,SI.GURS.KGI:LETALISCA_P,SI.GURS.KGI:LETALISCA_T',
                    transparent: true,
                    url: wmsUrlPrivate,
                    type: 'overlay',
                    visible: false,
                    format: 'image/png',
                    maxZoom: 19,
                },
                {
                    name: "Nafta",
                    layers: 'SI.GURS.KGI:NAFTA_L,SI.GURS.KGI:NAFTA_P,SI.GURS.KGI:NAFTA_T',
                    transparent: true,
                    url: wmsUrlPrivate,
                    type: 'overlay',
                    visible: false,
                    format: 'image/png',
                    maxZoom: 19,
                },
                {
                    name: "Odpadki",
                    layers: 'SI.GURS.KGI:ODPADKI_L,SI.GURS.KGI:ODPADKI_P,SI.GURS.KGI:ODPADKI_T',
                    transparent: true,
                    url: wmsUrlPrivate,
                    type: 'overlay',
                    visible: false,
                    format: 'image/png',
                    maxZoom: 19,
                },
                {
                    name: "Optični priključki",
                    layers: 'SI.GURS.KGI:OPT_PRIKL_1,SI.GURS.KGI:OPT_PRIKL_2,SI.GURS.KGI:OPT_PRIKL_3,SI.GURS.KGI:OPT_PRIKL_4,SI.GURS.KGI:OPT_PRIKL_5,SI.GURS.KGI:OPT_STAVBE,SI.GURS.KGI:OPT_SUBJEKT_1,SI.GURS.KGI:OPT_SUBJEKT_2,SI.GURS.KGI:OPT_SUBJEKT_3,SI.GURS.KGI:OPT_SUBJEKT_4',
                    transparent: true,
                    url: wmsUrlPrivate,
                    type: 'overlay',
                    visible: false,
                    format: 'image/png',
                    maxZoom: 19,
                },
                {
                    name: "Pristanišča",
                    layers: 'SI.GURS.KGI:PRISTANISCA_L,SI.GURS.KGI:PRISTANISCA_P,SI.GURS.KGI:PRISTANISCA_T',
                    transparent: true,
                    url: wmsUrlPrivate,
                    type: 'overlay',
                    visible: false,
                    format: 'image/png',
                    maxZoom: 19,
                },
                {
                    name: "Toplotna energija",
                    layers: 'SI.GURS.KGI:TOPLONA_ENERGIJA_L,SI.GURS.KGI:TOPLONA_ENERGIJA_P,SI.GURS.KGI:TOPLONA_ENERGIJA_T',
                    transparent: true,
                    url: wmsUrlPrivate,
                    type: 'overlay',
                    visible: true,
                    format: 'image/png',
                    maxZoom: 19,
                },
                {
                    name: "Vodna infrastruktura",
                    layers: 'SI.GURS.KGI:VODNA_INFRASTRUKTURA_L,SI.GURS.KGI:VODNA_INFRASTRUKTURA_P,SI.GURS.KGI:VODNA_INFRASTRUKTURA_T',
                    transparent: true,
                    url: wmsUrlPrivate,
                    type: 'overlay',
                    visible: true,
                    format: 'image/png',
                    maxZoom: 19,
                },
                {
                    name: "Vodovod",
                    layers: 'SI.GURS.KGI:VODOVOD_L,SI.GURS.KGI:VODOVOD_P,SI.GURS.KGI:VODOVOD_T',
                    transparent: true,
                    url: wmsUrlPrivate,
                    type: 'overlay',
                    visible: true,
                    format: 'image/png',
                    maxZoom: 19,
                },
                {
                    name: "Zemeljski plin",
                    layers: 'SI.GURS.KGI:ZEMELJSKI_PLIN_L,SI.GURS.KGI:ZEMELJSKI_PLIN_P,SI.GURS.KGI:ZEMELJSKI_PLIN_T',
                    transparent: true,
                    url: wmsUrlPrivate,
                    type: 'overlay',
                    visible: true,
                    format: 'image/png',
                    maxZoom: 19,
                },
                {
                    name: "Žičnice",
                    layers: 'SI.GURS.KGI:ZICNICE_L,SI.GURS.KGI:ZICNICE_P,SI.GURS.KGI:ZICNICE_T',
                    transparent: true,
                    url: wmsUrlPrivate,
                    type: 'overlay',
                    visible: false,
                    format: 'image/png',
                    maxZoom: 19,
                }
            ],
            communalEquipmentLegendDiv: null
        }
    },
    computed: {
        leafletMapObject: function(){
            return this.$refs.map.leafletMapObject;
        }
    },
    methods: {
        addcommunalEquipmentLegend: function(){
            const app = this;
            let legend = L.control({position: 'bottomleft'});
            legend.onAdd = function () {
                var div = L.DomUtil.create('div', 'leaflet-legend');
                div.innerHTML += "<img src='/static/images/GJI_legend.png'>"
                app.communalEquipmentLegendDiv = div;
                return div;
            }
            legend.addTo(this.leafletMapObject);
        },
        addLegendToggleButton: function(){
            const app = this;
            let legend = L.control({position: 'bottomleft'});
            legend.onAdd = function () {
                var div = L.DomUtil.create('button', 'btn btn-xs')
                div.innerHTML += 'Legenda'
                L.DomEvent.addListener(div, 'click', function () {
                    app.communalEquipmentLegendDiv.toggleAttribute('hidden');
                });
                return div;
            }
            legend.addTo(this.leafletMapObject);
        },
    },
    mounted() {
        this.addcommunalEquipmentLegend();
        this.addLegendToggleButton();
    }
}
</script>

<style>
    @import "~leaflet/dist/leaflet.css";
    @import "~leaflet-fullscreen/dist/leaflet.fullscreen.css";
</style>