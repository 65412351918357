import Vue from "vue"
import Vuex from "vuex"

import ApiService from "@/components/utils/api.service.js"
import ApiCalls from "@/components/utils/api.calls.js"

Vue.use(Vuex);

ApiService.init();

const store = new Vuex.Store({
    state() {
        return {
            fetched: false,
            countryPlugin: {},
        }
    },
    mutations: {
        setFetched(state, val) {
            state.fetched = val;
        },
        setCountryPlugin(state, val) {
            state.countryPlugin = val;
        },
    },
    actions: {
        retrieveCountryPlugin(context) {
            // prevent unnecessary requests
            if (context.state.fetched)
                return;
            context.commit("setFetched", true);
            
            return ApiCalls.getCountryPluginData()
                .then(response => {
                    context.commit("setCountryPlugin", response.data);
                })
        },
    },
})

export default {
    beforeCreate() {
        this.$countryStore = store;
    },
    computed: {
        isCountryPluginLoaded() {
            const loaded = Object.keys(this.$countryStore.state.countryPlugin).length > 0;
            if (loaded)
                this.onCountryPluginLoad();
            return loaded;
        }
    },
    mounted() {
        this.$countryStore.dispatch("retrieveCountryPlugin");
    },
    methods: {
        getCountryPlugin(key) {
            if (key in this.$countryStore.state.countryPlugin)
                return this.$countryStore.state.countryPlugin[key];
            return null;
        },
        onCountryPluginLoad() {
            // Override this method in your component
        },
    },
}
