import ApiService from "@/components/utils/api.service.js"
import { Swal } from "@/components/utils/swal.alerts.js";
import i18n from "@/i18n.js";

export default {
    getCountryPluginData: function() {
        return ApiService
            .get("/api/v1/country-plugin-data")
            .then(response => {
                return response;
            })
    },
    getConstanceConfig: function() {
        return ApiService
            .get("/api/v1/constance-config")
            .then(response => {
                return response;
            })
    },
    getGursSessionId: function() {
        return ApiService
            .get("/api/v1/gurs-session-id")
            .then(response => {
                return response.data;
            })
    },
    getWeDocs: function(key) {
        return ApiService
            .get("/documentation/help/" + key)
            .then(response => {
                return response;
            })
    },

    getRealEstateData: function(re_key) {
        return ApiService
            .get(`/amas/real-estate/api/real-estate/${re_key}`)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                if(error.response && error.response.status===404) {
                    Swal({   
                        title: i18n.t("general:warning"),
                        text: `${i18n.t("real_estate:invalid_re_key:error")}: '${re_key}'`,
                        icon: "warning"
                    })
                    return null;
                } else {
                    throw error;
                }
            })
    },
    getRealEstateGeometry: function(re_key) {
        return ApiService
            .get(`/amas/real-estate/api/real-estate/${re_key}/geometry`)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                if(error.response && error.response.status===404) {
                    Swal({   
                        title: i18n.t("general:warning"),
                        text: `${i18n.t("real_estate:invalid_re_key:error")}: '${re_key}'`,
                        icon: "warning",
                        confirmButtonText: i18n.t("general:ok")
                    })
                } else {
                    throw error;
                }
            })
    },

    getLegalStatus: function(re_key) {
        return ApiService
            .get(`/amas/analysis/legal/api/${re_key}`)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                throw error;
            })
    },

    getParcelsByGps: function(lat, lng) {
        /*
        Returns a Parcel objects containing {lat, lng} gps point
        */
        return ApiService
            .get("/amas/real-estate/api/geosearch/parcels", {
                params: {
                    geom: { "type": "Point", "coordinates": [lng, lat] },
                    lookup: "ST_Contains"
                }
            })
            .then(response => {
                return response.data;
            })
    },
    
    getParcelsByGpsFromOSS: function(lat, lng) {
        /*
        Returns a Parcel objects containing {lat, lng} gps point
        */
        return ApiService
            .get("/amas/real-estate/api/geosearch/parcels_oss", {
                params: {
                    lat: lat,
                    lng: lng,
                }
            })
            .then(response => {
                return response.data;
            })
    },

    getValuationSubjectEstimation: function(re_key) {
        /*
        For provided 're_key', return estimation of a valuation subject.
        */
        return ApiService
            .get(`/amas/real-estate/api/valuation-subject-estimate/${re_key}`)
            .then(response => {
                return response.data;
            })
    },
}
