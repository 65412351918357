import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

export function Swal(data){
    return Vue.swal(data);
}

export function fireLogoutWarning(){
    Swal({
        title: "Bili ste odjavljeni",   
        text: "Ponovno se prijavite za nadaljno uporabo.", 
        icon: "info",
        confirmButtonText: "Pojdi na prijavo",
    }).then(function(){
        window.location.href="/logout";
    });
}
